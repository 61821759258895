var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "vuelidate" }, [
    _c("div", { staticClass: "vuelidate-grid" }, [
      _c("div", { staticClass: "vuelidate-grid__form" }, [
        _c("h2", { staticClass: "heading" }, [
          _vm._v("Form validation using Vuelidate")
        ]),
        _c("p", { staticClass: "paragraph" }, [
          _vm._v(
            "Using 3rd party form validation plugin to validate form fields. When they are invalid they show a CSS animation (shake)."
          )
        ]),
        _c("form", [
          _c("div", { staticClass: "vuelidate-form-grid" }, [
            _c(
              "label",
              {
                staticClass: "vuelidate__label vuelidate__label--input-field",
                class: {
                  "has-error": _vm.$v.username.$error,
                  "is-valid":
                    !_vm.$v.username.$error && !_vm.$v.username.$invalid
                }
              },
              [_vm._v(" Name ")]
            ),
            _c(
              "div",
              {
                staticClass: "vuelidate__values",
                class: { "do-shake": _vm.$v.username.$error }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.username,
                      expression: "username",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "vuelidate__input",
                  class: {
                    "has-error": _vm.$v.username.$error,
                    "is-valid":
                      !_vm.$v.username.$error && !_vm.$v.username.$invalid
                  },
                  attrs: { type: "text", name: "username" },
                  domProps: { value: _vm.username },
                  on: {
                    blur: [
                      function($event) {
                        return _vm.$v.username.$touch()
                      },
                      function($event) {
                        return _vm.$forceUpdate()
                      }
                    ],
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.username = $event.target.value.trim()
                    }
                  }
                }),
                _vm.$v.username.$error
                  ? _c("small", { staticClass: "vuelidate__error" }, [
                      _vm._v(" Please enter you name ")
                    ])
                  : _vm._e(),
                !_vm.$v.username.minLen
                  ? _c("small", { staticClass: "vuelidate__error" }, [
                      _vm._v(
                        " Name must be at least " +
                          _vm._s(_vm.$v.username.$params.minLen.min) +
                          " characters long "
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _c(
              "label",
              {
                staticClass: "vuelidate__label vuelidate__label--input-field",
                class: {
                  "has-error": _vm.$v.email.$error,
                  "is-valid": !_vm.$v.email.$error && !_vm.$v.email.$invalid
                }
              },
              [_vm._v(" E-mail ")]
            ),
            _c(
              "div",
              {
                staticClass: "vuelidate__values",
                class: { "do-shake": _vm.$v.email.$error }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.email,
                      expression: "email",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "vuelidate__input",
                  class: {
                    "has-error": _vm.$v.email.$error,
                    "is-valid": !_vm.$v.email.$error && !_vm.$v.email.$invalid
                  },
                  attrs: { type: "email", name: "email" },
                  domProps: { value: _vm.email },
                  on: {
                    blur: [
                      function($event) {
                        return _vm.$v.email.$touch()
                      },
                      function($event) {
                        return _vm.$forceUpdate()
                      }
                    ],
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.email = $event.target.value.trim()
                    }
                  }
                }),
                _vm.$v.email.$error
                  ? _c("small", { staticClass: "vuelidate__error" }, [
                      _vm._v(" Please enter an e-mail address ")
                    ])
                  : _vm._e(),
                !_vm.$v.email.email
                  ? _c("small", { staticClass: "vuelidate__error" }, [
                      _vm._v(" Please provide a valid e-mail address ")
                    ])
                  : _vm._e()
              ]
            ),
            _c(
              "label",
              {
                staticClass: "vuelidate__label vuelidate__label--input-field",
                class: {
                  "has-error": _vm.$v.age.$error,
                  "is-valid": !_vm.$v.age.$error && !_vm.$v.age.$invalid
                }
              },
              [_vm._v(" Age ")]
            ),
            _c(
              "div",
              {
                staticClass: "vuelidate__values",
                class: { "do-shake": _vm.$v.age.$error }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number.trim",
                      value: _vm.age,
                      expression: "age",
                      modifiers: { number: true, trim: true }
                    }
                  ],
                  staticClass: "vuelidate__input",
                  class: {
                    "has-error": _vm.$v.age.$error,
                    "is-valid": !_vm.$v.age.$error && !_vm.$v.age.$invalid
                  },
                  attrs: {
                    type: "number",
                    placeholder:
                      "Minimum age is " + _vm.$v.age.$params.minVal.min
                  },
                  domProps: { value: _vm.age },
                  on: {
                    blur: [
                      function($event) {
                        return _vm.$v.age.$touch()
                      },
                      function($event) {
                        return _vm.$forceUpdate()
                      }
                    ],
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.age = _vm._n($event.target.value.trim())
                    }
                  }
                }),
                _vm.$v.age.$error
                  ? _c("small", { staticClass: "vuelidate__error" }, [
                      _vm._v(
                        " Minimum age is " +
                          _vm._s(_vm.$v.age.$params.minVal.min) +
                          " "
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _c(
              "label",
              { staticClass: "vuelidate__label vuelidate__label--input-field" },
              [_vm._v(" Available coupons ")]
            ),
            _c("div", { staticClass: "vuelidate__values" }, [
              _c(
                "ul",
                { staticClass: "vuelidate__coupons" },
                _vm._l(_vm.coupons, function(coupon, index) {
                  return _c("li", { key: index }, [
                    _vm._v(" " + _vm._s(coupon) + " ")
                  ])
                }),
                0
              )
            ]),
            _c(
              "label",
              {
                staticClass: "vuelidate__label vuelidate__label--input-field",
                class: {
                  "has-error": _vm.$v.coupon.$error,
                  "is-valid": _vm.isCouponValid
                }
              },
              [_vm._v(" Coupon ")]
            ),
            _c(
              "div",
              {
                staticClass: "vuelidate__values",
                class: {
                  "do-shake": _vm.$v.coupon.$error,
                  "is-valid": _vm.isCouponValid
                }
              },
              [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.coupon,
                        expression: "coupon",
                        modifiers: { trim: true }
                      }
                    ],
                    staticClass: "vuelidate__input",
                    class: {
                      "has-error": _vm.$v.coupon.$error,
                      "is-valid": _vm.isCouponValid
                    },
                    attrs: {
                      type: "text",
                      placeholder: "Try out one of the above coupon codes"
                    },
                    domProps: { value: _vm.coupon },
                    on: {
                      blur: [
                        function($event) {
                          return _vm.$v.coupon.$touch()
                        },
                        function($event) {
                          return _vm.$forceUpdate()
                        }
                      ],
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.coupon = $event.target.value.trim()
                      }
                    }
                  }),
                  _vm.$v.coupon.$error
                    ? _c("small", { staticClass: "vuelidate__error" }, [
                        _vm._v(" Coupon code is invalid ")
                      ])
                    : _vm._e()
                ])
              ]
            ),
            _c(
              "label",
              {
                staticClass: "vuelidate__label",
                class: { "is-valid": _vm.terms }
              },
              [_vm._v(" Terms ")]
            ),
            _c(
              "div",
              {
                staticClass: "vuelidate__values",
                class: { "do-shake": _vm.$v.terms.$error }
              },
              [
                _c("label", { class: { "is-valid": _vm.terms } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.terms,
                        expression: "terms"
                      }
                    ],
                    attrs: {
                      type: "checkbox",
                      I: "",
                      accept: "",
                      the: "",
                      terms: "",
                      of: "",
                      usage: ""
                    },
                    domProps: {
                      checked: Array.isArray(_vm.terms)
                        ? _vm._i(_vm.terms, null) > -1
                        : _vm.terms
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.terms,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.terms = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.terms = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.terms = $$c
                          }
                        },
                        function($event) {
                          return _vm.$v.terms.$touch()
                        }
                      ]
                    }
                  }),
                  _vm._v(" I accept the terms of usage ")
                ]),
                _vm.$v.terms.$error
                  ? _c("small", { staticClass: "vuelidate__error" }, [
                      _vm._v(
                        " You must accept our terms and conditions to proceed "
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _c(
              "button",
              {
                staticClass: "vuelidate__button button button--02",
                attrs: { disabled: _vm.$v.$invalid },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.submitForm($event)
                  }
                }
              },
              [_vm._v(" Submit form ")]
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "vuelidate-grid__validation" }, [
        _c("h2", { staticClass: "heading" }, [
          _vm._v("Vuelidate validation object")
        ]),
        _c("div", { staticClass: "vuelidate__values" }, [
          _vm._m(0),
          _c("pre", [_vm._v(_vm._s(_vm.$v))])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("em", [_vm._v("Scroll for more data")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }